// src/errors.ts
var ChainNotConfiguredForConnectorError = class extends Error {
  constructor({
    chainId,
    connectorId
  }) {
    super(`Chain "${chainId}" not configured for connector "${connectorId}".`);
    this.name = "ChainNotConfiguredForConnectorError";
  }
};
var ConnectorNotFoundError = class extends Error {
  constructor() {
    super(...arguments);
    this.name = "ConnectorNotFoundError";
    this.message = "Connector not found";
  }
};

export {
  ChainNotConfiguredForConnectorError,
  ConnectorNotFoundError
};
